import cryptoJs from 'crypto-js'
import { useCookie, useRuntimeConfig } from '#imports'

export default () => {
  const generateRandomString = (length: number): string => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      result += charset[randomIndex]
    }
    return result
  }

  const base64Url = (string: string) => {
    return string.toString(cryptoJs.enc.Base64)
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')
  }

  const buildAuthParams = () => {
  // Generate a random state string
    const state = generateRandomString(20)

    // Generate a random code verifier
    const codeVerifier = generateRandomString(64)

    // We need to save these so we can confirm the server is solid and we're not being spoofed
    // Then we'll actually send the verifier to the server to get the token
    const authStateCookie = useCookie('auth-state')
    authStateCookie.value = state

    const authVerifierCookie = useCookie('auth-verifier')
    authVerifierCookie.value = codeVerifier

    // Hash the code verifier using SHA-256 and encode as base64
    const codeChallenge = base64Url(cryptoJs.SHA256(codeVerifier))

    const config = useRuntimeConfig()

    const baseUrl = window.location.origin

    if (config.public.reefAuthClientId) {
      const params = {
        client_id: config.public.reefAuthClientId,
        redirect_uri: `${baseUrl}${config.public.reefAuthRedirect}`,
        response_type: 'code',
        scope: '',
        state,
        code_challenge: codeChallenge,
        code_challenge_method: 'S256',
      }

      return params
    }
    return null
  }

  return { buildAuthParams }
}
