<script setup lang="ts">
import queryString from 'query-string'
import useReefAuth from '@atlantis/composables/useReefAuth'
import { useRuntimeConfig } from '#imports'

defineSlice({
  name: { label: 'Reef Login', group: 'Forms' },
  description: 'Log in fom for Reef Owners.',
  fields: {},
  templates: [{
    label: 'Reef Login',
    fields: {},
  }],
})
const rememberMe = useCookie('remember_me', false)

const action = ref('')
if (process.client) {
  const { buildAuthParams } = useReefAuth()
  const params = buildAuthParams()

  const baseUrl = window.location.origin
  params.redirect_uri = `${baseUrl}/reef/auth/callback`

  const config = useRuntimeConfig()
  action.value = `${config.public.voixApiBaseUrl}/auth/reef?${queryString.stringify(params)}`
}
</script>

<template>
  <div class="bg-white shadow py-12 px-8 lg:pl-3 lg:py-6">
    <ClientOnly>
      <form :action="action" method="POST" class="flex flex-col lg:flex-row lg:items-center min-w-[280px] space-y-6 lg:space-y-0 lg:space-x-6 *:font-sans *:font-bold *:uppercase *:text-xs">
        <div class="flex flex-col space-y-1">
          <label class="" for="login-email">E-mail Address</label>
          <input id="login-email" type="email" name="email" placeholder="email" class="py-2 px-3 border-zinc-300 border rounded-sm font-sans2 font-normal text-gray-700">
        </div>
        <div class="flex flex-col space-y-1">
          <label class="" for="login-password">Password</label>
          <input id="login-password" type="password" name="password" placeholder="password" class="py-2 px-3 border-zinc-300 border rounded-sm font-sans2 font-normal text-gray-700">
        </div>
        <div>
          <div class="flex items-center space-x-2">
            <label class="" for="login-remember">Remember Me</label>
            <input id="login-remember" v-model="rememberMe" type="checkbox" name="remember" class="">
          </div>
          <div>
            <a href="/password/reset" class=" text-glueblue-600 font-normal">Forgot Your Password?</a>
          </div>
        </div>
        <input type="submit" class="bg-glueblue-600 text-white self-stretch py-4 lg:-my-6 px-6" value="Login">
      </form>
    </ClientOnly>
  </div>
</template>
